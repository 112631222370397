@import "../../utils/includes.scss";

// ========
// Fonts
// ========

$fontFamily: 'Avenir Next Regular', 'sans-serif';
$boldFontFamily: 'Acura Bespoke', 'sans-serif';
$boldFontWeight: normal;
$titleFontFamily: $boldFontFamily;
$titleFontWeight: $boldFontWeight;
$isUppercase: uppercase;

// ========
// Colors
// ========

$textColor: #2c2c2c;
$linkColor: #2c2c2c;
$labelColor: #707070;
$formColor: #2c2c2c;
$utilColor: #334C96;
$priceColor: #2c2c2c;
$lightColor: #707070;
$btnColorStep: #ffffff;
$titleColor: #000000;
$titleSecondaryColor: #ffffff;
$subtitleColor: #000000;
$subtitleSecondaryColor: #ffffff;
$successColor: #2DB253;
$warningColor: #f7941d;
$errorColor: #DA2C2E;
$redColor: #DA2C2E;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: #f2f2f2;

$bgBtnAlpha: #334C96;
$colorBtnAlpha: #ffffff;
$btnColorAlpha: #ffffff;

$bgBtnBeta: transparent;
$colorBtnBeta: #2c2c2c;
$btnColorBeta: #2c2c2c;

$colorBtnOutlineAlpha: #2c2c2c;
$colorBtnOutlineBeta: #2c2c2c;

$paddingButtonPrimary: 16px 14px;
$paddingButtonSecondary: 11px 15px;
$borderRadiusButton: 50px;

$borderRadiusForm: 0px;
$borderColorForm: #000;
$borderSizeForm: 1px solid;

$calculatorTabsColor: #000000;
$calculatorTabsTextColor: #ffffff;
$calculatorCloseTabs: #505050;
$calculatorCloseTabsColor: #686869;
$calculatorMainColor: #ffffff;
$calculatorButton: #334C96;

$garageBackground: #ffffff;
$garageTextColor: #2c2c2c;
$garageCarNoteColor: #f8f8f8;

$bgTopbarLogin: #21232e;
$colorTopbarLogin: #c8c9cb;

$progressBarTitleBG: #002D62;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #ffffff;
$progressBarTitleCloseColor: #334C96;
$progressBarCloseButtonBG: #334C96 ;
$progressBarCloseButtonColor: #ffffff;

$paymentCalculatorClosedBG: #2C2C2C;
$paymentCalculatorClosedColor: #e8e8e8;
$paymentCalculatorPanelBG: #21232E;
$paymentCalculatorTermWrapperBG: #363a4d;
$paymentCalculatorTermWrapperColor: #ffffff;
$paymentCalculatorTermSelectedWrapperBG: #ffffff;
$paymentCalculatorTermSelectedColor: #ffffff;
$paymentCalculatorTermBG: #252835;
$paymentCalculatorTermColor: #ffffff;

// ========
// Styles
// ========

.widget-sr{
    @include clearfix;
    width: 100%;
    &.makes__acura{
        font-family: $fontFamily;
        font-size: 14px;
        color: $textColor;
        background-color: $bgPrimaryColor;
        &.Checkout{
            background-color: $bgSecondaryColor
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper{
            .TransactionSummaryUnit--single.is-validate{
                border-bottom-color: #334c96!important;
            }
        }
        .TransactionSummaryUnit--single{
            .validFlag:before{
                background-color: transparent;
                background-image: -webkit-linear-gradient(304deg,transparent 50%,#334c96 55%) !important;
            }
        }
        .TransactionProgressBar--progression{
            background-color: #334c96 !important;
        }
        .confirm_deposit--icon,
        .confirm_financing--icon{
            border: 1px solid #334c96 !important;
            .icon,.icon-Crochet{
                color: #334c96 !important;
            }
        }
        .confirm_deposit--title,
        .confirm_financing--title{
            color: #334c96;
        }
        .section-checkout-header__progressBar-stage.validated{
            .picto-showroom:first-child{
                color: #334c96;
            }
        }
        .Appointment__content .appointment__steps--single.is-validated {
            .icon-calendar{
                color: #334c96 !important;
            }
        }
        .doneLabel {
            color: #334c96 !important;
            .icon-Crochet{
                color: #334c96 !important;
            }
        }

        .message-handler{
            &.warning,
            &.success,{
                background-color: #334c96 !important;
            }
        }
        .has-success-background{
            background-color: #334c96 !important;
        }
        &.-hiddenBuyOnline {
            .showroom-vdp-used,
            .showroom-vdp-new{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        &.-hiddenCatalogBuyOnline {
            .showroom-build-and-price__content{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }

        /*****************************
         Nouvelle Charte 2024 Button
         ****************************/
        .sr-button-1,
        .sr-button-2{
            letter-spacing: 1px;
            height: 45px;
            font-family: $boldFontFamily;
            font-size: 13px !important;
            padding: 10px 14px !important;
            line-height: 17px !important;
            &.is-small{
                min-height: 0;
            }
            &:hover{
                opacity: 1 !important;
            }
        }

        .sr-button-1{
            &:hover{
                background: #002D62;
            }
        }
        .sr-button-2{
            border: 1px solid $btnColorBeta !important;
            &:hover{
                border: 1px solid $btnColorBeta;
                background: $btnColorBeta;
                color: #FFFFFF;
            }
        }
        .sr-button-outline-1,
        .sr-button-outline-2{
            font-family: $boldFontFamily;
            &:hover{
                opacity: 1 !important;
                background: #2c2c2c;
                color: #ffffff;
            }
        }


        /*********************
         Nouvelle Charte 2024
         *********************/
        .sr-progressBar{
            .TransactionSummaryUnit--single:first-child{
                border-bottom-color: #c7c7c7 !important;
            }
            .TransactionSummaryUnit--single:nth-child(2){
                border-bottom-color: #707070 !important;
            }
            .TransactionSummaryUnit--single:nth-child(3){
                border-bottom-color: #2c2c2c !important;
            }
        }
        .TransactionAction--single{
            .label{
                color: $textColor;
            }
        }
        .payment-calculator-wrapper{
            z-index: auto;
        }

        /*********************************
         Nouvelle charte Listing Inventory
         ********************************/
        .listing-used{

            .listing-used-filters-head .listing-used-filters-head-title{
                color: $textColor;
            }
            .listing-used-filters-title{
                color: $textColor;
            }
            .model-list li .model-label{
                color: $textColor;
            }
            .InputNumber .InputNumber--label{
                color: $textColor;
            }
            .listing-used-results .number-results{
                color: $textColor;
            }
            .listing-new-tile .listing-tile-car-save{
                color: $textColor;
            }
            .listing-new-tile .listing-new-tile-drivePowerTrains .new-car-name{
                color: $textColor;
            }
            .financing-section.tile-payment-info{
                .tile-frequency,
                .tile-payment-rate,
                .tile-payment-detail{
                    color: $textColor;
                }
            }
            .listing-used-filters-title{
                .slider-labels div{
                    color: $textColor;
                }
            }
            .sr-slide .rc-slider-track,
            .sr-slide .rc-slider-handle{
                background-color: $redColor !important;
            }
            .rc-slider .rc-slider-handle{
              border: 2px solid $redColor;
            }
            .new-car-motor{
                color: $textColor;
            }
            .frequency-highlight{
                color: $textColor;
            }
            .tile-payment-amount{
                span{
                    color: $textColor;
                    font-style: normal;
                }
            }
            .financing-section.tile-payment-info .tile-payment-rate .frequency-highlight{
                font-style: normal;
                color: $textColor;
            }
            .method-button-payment-bar button:after{
                background-color: $redColor;
            }
          .listing-used-filters-head-button{
            .current-filter:after{
             background-color: $redColor;
            }
          }
          .InputCheckbox input[type=checkbox]:checked+label span:before,
          .site-container [type=checkbox]:checked+label,
          .site-container [type=checkbox]:not(:checked)+label{
            line-height: 15px;
          }
        }

        /*********************************
         Nouvelle charte Details Inventory
         ********************************/
        .section-summary__content .trim{
            color: $textColor;
        }
        .car-equipments_summary{
            .specs-bloc,
            .features-bloc{
                color: $textColor;
            }
        }
        .vehicle-overview__vehicle-title{
            p{
                color: $textColor;
            }
        }
        .cash-section .tile-payment-info{
            p,span{
                color: $textColor;
            }
        }
        .tile-payment-info .payment-rebates .payment-rebate{
            color: $textColor;
        }
        .cash-section.tile-payment-info .payment-row {
            color: $textColor;
        }
        .price-cash{
            color: $textColor;
        }
        .showroom-vdp-used-car-info{
            .car-options{
                color: $textColor!important;
            }
        }
        .listing-tile-all-price{
            .seling-price-disclaimer{
                color: $textColor;
            }
        }
        .showroom-vdp-used-features-vehicle-info-title,
        .showroom-vdp-used-features-vehicle-info-fuel-name,
        .showroom-vdp-used-features-vehicle-info-fuel-info{
            color: $textColor;
        }
        /*********************************
         Nouvelle charte Shop Online
         ********************************/
        .catalog{
            .section-catalog-filter .catalog-filter__trade-in-wrapper .trade-in-info{
                color: $textColor;
            }
            .vehicle-tile-rebate-single{
                color: $textColor;
            }
            .vehicle-description div{
                color: $textColor;
            }
            .vehicle-payment-title{
                color: $textColor;
            }
            .vehicle-payment-wrapper{
                .vehicle-payment-rate,
                .vehicle-payment-cashdown,
                .vehicle-payment-cost-credit{
                    color: $textColor;
                }
            }
            .vehicle-payment-amount-note{
                color: $textColor;
            }
            .style-color__label{
                color: $textColor;
            }
        }
        .car-equipments_summary{
            .specs-title{
                color: $textColor !important;
            }
            .specs-bloc--content{
                color: $textColor;
                span{
                    color: $textColor;
                }
            }
        }
        .vehicle-overview__summary,
        .vehicle-overview{
            .vehicle-overview__vehicle-title{
                .vehicle-make,
                .vehicle-model{
                    color: #000000;
                }
            }
            .total-cost-of-credit{
                color: $textColor;
            }
            .tile-payment-rate{
                color: $textColor;
                .frequency-highlight{
                    color: $textColor;
                }
            }
            .frequency-highlight{
                color: $textColor;
            }
        }

        // **********************
        // Calculator
        // **********************
        .purchase-methods-cash{
            .obligationsTotal .amountLineItem .sub,
            .pdsf_calculator .amountLineItem .sub{
                color: #FFFFFF;
            }
        }
        .purchase-methods-tab__tabs li{
            opacity: 1;
            button{
                font-family: $boldFontFamily;
            }
        }
        .calculatorColumn{
            .is-util,
            .sr-link{
                color: #ffffff !important;
            }
            .amountLineItem{
                .label{
                    font-family: $boldFontFamily;
                }
            }
        }
        .calculator-term{
            .taxesIncluded-section{
                span{
                    color: #707070 !important;
                }
            }
        }
        .taxesIncluded-section{
            span{
                color: #ffffff!important;
            }
        }
        .customize-payment-slider-price-wrapper{
            .customize-payment-title{
                color: #ffffff;
            }
        }
        .calculator-cash-down{
            .calculator-cash-down-title{
                color: #ffffff;
            }
        }
        .calculator-tradeIn{
            .calculator-tradeIn-title{
                color: #ffffff;
            }
        }
        .section-distance{
            .distance_title{
                color: #ffffff;
            }
        }
        .TradeInTile--content-official-title{
            p{
                color: $textColor !important;
            }
        }

        // **********************
        // Appointment Section
        // **********************
        .appointment__steps{
            .sr-subtitle-2{
                color: #000000 !important;
            }
            .timeSaved{
                span{
                    color: $textColor;
                }
            }
            .visit__button{
                button{
                    color: $textColor;
                    &.selected{
                        color: #ffffff;
                    }
                }
            }
        }

        &.trade-is-on-top{
            .listing-used-car-search>div:last-child {
                display: flex;
                flex-direction: column;
                #instant-estimate-bar-wrapper{
                    order: 1;
                    margin-top: 0 !important;
                }
                .listing-tiles{
                    order: 2;
                }
                .listing-used-button-loading{
                    order: 3;
                }
            }
        }

        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include sidebarsummary;
        @include login;
        @include garage;
        @include protections;

        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }
}

@include breakpoint(desktop){
    .widget-sr{
        &.makes__acura{
            .payment-calculator-wrapper{
                z-index: 9;
            }
        }
    }
}